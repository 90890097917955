<template>
  <div class="video-container">
    <van-nav-bar title="视频" />
    <van-empty description="正在努力开发中..." />
  </div>
</template>

<script>
export default {
  name: 'VideoIndex',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.video-container {
}
</style>
